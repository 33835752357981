import { memo } from 'react';
import { type DisallowWrapProps, type HasHrefOrOnPress, type ClickableTextProps, ClickableText } from 'ClickableText';

type BlockLinkProps = HasHrefOrOnPress &
    Pick<DisallowWrapProps, 'anchorIcon'> &
    Pick<ClickableTextProps, 'children' | 'color' | 'className'>;

function BlockLinkComponent(props: BlockLinkProps) {
    const { children, ...rest } = props;
    return (
        <div className="block">
            <ClickableText weight="medium" {...rest}>
                {children}
            </ClickableText>
        </div>
    );
}

export const BlockLink = memo(BlockLinkComponent) as typeof BlockLinkComponent;
